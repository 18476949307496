



















import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import deviceCollection, { Device } from '@/models/device'
import DeviceItem from '@/components/manage/DeviceItem.vue'
import AuthStore from '@/store/AuthStore'

@Component<DeviceList>({
  components: {
    DeviceItem
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class DeviceList extends Vue {
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Device>[] = []

  @Watch('selectedCompany')
  onChangeCompany (): void {
    this.subscribe()
  }

  @Watch('selectedGroup')
  onChangeGroup (): void {
    this.subscribe()
  }

  get selectedCompany (): unknown | null{
    return AuthStore.selectedCompany
  }

  get selectedGroup (): unknown | null{
    return AuthStore.selectedGroup
  }

  get deviceRef (): firebase.firestore.Query<Device> {
    if (AuthStore.selectedGroup) {
      return deviceCollection.where('group', '==', AuthStore.selectedGroup.ref)
    } else {
      if (AuthStore.selectedCompany) {
        return AuthStore.isCompanyAdmin
          ? deviceCollection.where('company', '==', AuthStore.selectedCompany.ref)
          : deviceCollection.where('group', 'in', AuthStore.groupsInCompanies.map(g => g.ref))
      } else {
        return AuthStore.isAdmin
          ? deviceCollection
          : deviceCollection.where('company', 'in', AuthStore.companies.map(c => c.ref))
      }
    }
  }

  subscribe (): void {
    this.unsubscribe = this.deviceRef.limit(100).onSnapshot(sn => {
      this.docs = sn.docs
    })
  }
}
