






























import { Component, Vue } from 'vue-property-decorator'
import DeviceList from '@/components/manage/DeviceList.vue'
import CompanySelect from '@/components/manage/CompanySelect.vue'
import GroupSelect from '@/components/manage/GroupSelect.vue'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import AuthStore from '@/store/AuthStore'
import CompanyNotFound from '@/components/common/CompanyNotFound.vue'

@Component<DeviceIndex>({
  components: { DeviceList, CompanySelect, GroupSelect, CompanyNotFound }
})
export default class DeviceIndex extends Vue {
  company: firebase.firestore.DocumentSnapshot<Company> | null = null

  get existsCompany (): boolean {
    return !!AuthStore.companies.length
  }

  get existsGroup (): boolean {
    return !!AuthStore.groups.length
  }

  get isCompanyAdmin (): boolean {
    return AuthStore.isCompanyAdmin
  }
}
